<template>
    <div>
        <feather-icon icon="PlusIcon" svgClasses="h-6 w-6" class="m-2 cd-p-pointer hover:text-primary" @click="openSetWord" v-if="uiType=='icon'"/>
        <p class="text-primary cd-p-pointer" @click="openSetWord" v-else>{{$t('components.msg.setWord')}}</p>
        <!-- 套用常用字句 視窗 -->
        <vs-popup :title="$t('components.msg.setWord')" :active.sync="popupSetWord" style="z-index:200001 !important;" @close="close">
            <vs-row v-if="options.length>0">
                <vs-col class="cd-form-group" vs-type="flex" vs-justify="flex-end">
                    <vs-button class="cd-button-2w" @click="applySetWord">{{$t('components.msg.apply')}}</vs-button>
                </vs-col>
                <vs-col class="cd-form-group" style="height: 60vh;">
                    <VuePerfectScrollbar style="height: 60vh;" :settings="settings">
                        <vs-checkbox v-for="(item, index) in options" :key="index" v-model="checkedSetWord[index]">
                            <p class="py-1" style="word-break: break-word;">{{item}}</p>
                        </vs-checkbox>
                    </VuePerfectScrollbar>
                </vs-col>
            </vs-row>
            <vs-row v-else>
                <vs-col vs-type="flex" vs-justify="center">
                    <p>{{$t('components.msg.noSetWord')}}</p>
                    <!-- 由於跳轉功能在某些頁面不正常 因此隱藏跳轉常用字句功能 -->
                    <!-- <p class="text-primary cd-p-pointer" @click="goToSetWord">{{$t('components.msg.createNow')}}</p> -->
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
    components: {
        VuePerfectScrollbar
    },
    props: {
        // 0:主觀項目 1:客觀項目 2:醫事人員建議 3:文字交談
        dataType: {
            type: String,
            required: true
        },
        uiType: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            popupSetWord: false,
            checkedSetWord: [],
            settings : {
                maxScrollbarLength : 60,
                wheelSpeed         : 0.30,
            },
        }
    },
    computed: {
        memoP:{
            get() {
                return this.$store.getters.memoP
            },
            set(val) {
                this.$store.commit('SET_MEMO_P', val)
            }
        },
        memo_s:{
            get() {
                return this.$store.getters.memoS
            },
            set(val) {
                this.$store.commit('SET_MEMO_S', val)
            }
        },
        memo_o:{
            get() {
                return this.$store.getters.memoO
            },
            set(val) {
                this.$store.commit('SET_MEMO_O', val)
            }
        },
        wordDiet:{
            get() {
                return this.$store.getters.wordDiet
            },
            set(val) {
                this.$store.commit('SET_MEMO_DIET', val)
            }
        },
        options(){
            var rs = []
            if(this.dataType=='memop/all'){
                var tmpWords = this.$store.getters.wordMemo
            }else if(this.dataType=='s/all'){
                var tmpWords = this.$store.getters.wordS
            }else if(this.dataType=='o/all'){
                var tmpWords = this.$store.getters.wordO
            }else if(this.dataType=='wd/all'){
                var tmpWords = this.$store.getters.wordWD
            }
            else if(this.dataType=='diet/all'){
                var tmpWords = this.$store.getters.wordDiet
            }
            
            if(tmpWords.length!=undefined){
                tmpWords.forEach(function(v){
                    rs.push(v.word)
                })
            }
            return rs
        },
    },
    methods: {
        // 開啟常用字句
        openSetWord(){
            this.fetchWords()
            this.popupSetWord=true
        },
        // 取 常用字句
        fetchWords() {
            var _self = this
            _self.$vs.loading()
            _self.$store.dispatch('fetchWords', _self.dataType).then(() => {
                _self.$vs.loading.close()
            })
        },
        // 套用常用字句 & 關閉視窗
        applySetWord(){
            var _self = this
            if(this.dataType=='memop/all'){
                var importMemo = ''
                // 匯入的選項組成字串
                _self.checkedSetWord.forEach(function(v,i){
                    if(v){
                        importMemo += _self.options[i] + '\n'
                    }
                })
                if(_self.memoP){
                    // 判斷最後一個字元是不是換行符號
                    let lastWord = _self.memoP.split("")[_self.memoP.split("").length-1]
                    if(lastWord != '\n' && lastWord != undefined) { _self.memoP = _self.memoP + '\n' }
                    _self.memoP += importMemo
                }else{
                    _self.memoP = importMemo
                }
            }else if(this.dataType=='s/all'){
                var importMemo = ''
                // 匯入的選項組成字串
                _self.checkedSetWord.forEach(function(v,i){
                    if(v){
                        importMemo += _self.options[i] + '\n'
                    }
                })
                if(_self.memo_s){
                    // 判斷最後一個字元是不是換行符號
                    let lastWord = _self.memo_s.split("")[_self.memo_s.split("").length-1]
                    if(lastWord != '\n' && lastWord != undefined) { _self.memo_s = _self.memo_s + '\n' }
                    _self.memo_s += importMemo
                }else{
                    _self.memo_s = importMemo
                }
            }else if(this.dataType=='o/all'){
                var importMemo = ''
                // 匯入的選項組成字串
                _self.checkedSetWord.forEach(function(v,i){
                    if(v){
                        importMemo += _self.options[i] + '\n'
                    }
                })
                if(_self.memo_o){
                    // 判斷最後一個字元是不是換行符號
                    let lastWord = _self.memo_o.split("")[_self.memo_o.split("").length-1]
                    if(lastWord != '\n' && lastWord != undefined) { _self.memo_o = _self.memo_o + '\n' }
                    _self.memo_o += importMemo
                }else{
                    _self.memo_o = importMemo
                }
            }else if(this.dataType=='wd/all'){
                var msg = ''
                _self.checkedSetWord.forEach(function(v,i){
                    if(v){
                        msg += _self.options[i] + '\n'
                    }
                })
                this.$emit('importMsg', _.trim(msg))
            }else if(this.dataType=='diet/all'){
                var importdiet = ''
                // 匯入的選項組成字串
                _self.checkedSetWord.forEach(function(v,i){
                    if(v){
                        importdiet += _self.options[i] + '\n'
                    }
                })
                this.$emit('importMsg', _.trim(importdiet))
            }
            _self.checkedSetWord = []
            _self.popupSetWord = false
        },
        // 跳轉到常用字句
        goToSetWord(){
            this.popupSetWord = false
            this.$emit('closeChatPopup')
            this.$emit('closeDietPopup')
            this.$router.push('/setting/setword')
        },
        close(){
            this.popupSetWord = false
        },
    }
}
</script>